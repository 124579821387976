$(document).ready(function() {
  $('.js--scroll-to-about').click(function () {
    $('html, body').animate({scrollTop: $('.section-about').offset().top}, 1000);
  });
  $('.js--scroll-to-services').click(function () {
    $('html, body').animate({scrollTop: $('.section-services').offset().top}, 1000);
  });
  $('.js--scroll-to-contact').click(function () {
    $('html, body').animate({scrollTop: $('.section-contact').offset().top}, 1000);
  });

  $("#submitButton").click(function(event){
    event.preventDefault();
    this.blur();

    const name = $('input[name="name"]').val() || "Contact Name";
    const email = $('input[name="email"]').val() || "Contact Email";
    const phoneNumber = $('input[name="phone-number"]').val() || "Phone Number";
    const findus = $('select[name="findus"]').val() || "Find Us";
    const message = $('textarea[name="message"]').val() || "Contact Message";
    $.post("https://www.neo-psych.com/api/contact",
      {
        name: name,
        email: email,
        phonenumber: phoneNumber,
        findus: findus,
        message: message
      },
      function(data, status){
        if(status === 200 || status === 'success'){
          $('input[name="name"]').val([]);
          $('input[name="email"]').val([]);
          $('input[name="phone-number"]').val([]);
          $('textarea[name="message"]').val([]);
          $('#myModal').modal();
        }
      });
  });

});

